/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
	  &:not(:last-of-type) {
		border-bottom: 0;
		border-radius: 0;
	  }
  
	  .card-header:first-child {
		border-radius: 0;
	  }
	}
  
	&:first-of-type {
	  border-bottom: 0;
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	&:last-of-type {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	}
  }
  
  ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
  }
  
  .example .pagination {
	margin-bottom: 0;
  }
  
  .pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
  }
  
  .page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	background-color: $white;
	color: $color;
	border:0px !important;
	font-size: 14px;
  
	&:hover {
	  z-index: 2;
	  text-decoration: none;
	  background-color: transparent;
	  box-shadow: none;
	}
  
	&:focus {
	  z-index: 2;
	  outline: 0;
	  box-shadow: none;
	  background-color:transparent;
	  color:$primary;
	}
  
	&:not(:disabled):not(.disabled) {
	  cursor: pointer;
	}
  }
  
  .page-item.active .page-link {
      border-radius:4px;
  }
  
  .page-item {
	&:first-child .page-link {
	  margin-left: 0;
	  border-top-left-radius: 3px;
	  border-bottom-left-radius: 3px;
	}
  
	&:last-child .page-link {
	  border-top-right-radius: 3px;
	  border-bottom-right-radius: 3px;
	}
  
	&.active .page-link {
	  z-index: 1;
	  color: $white;
  
	  &:hover {
		color: $white !important;
	  }
	}
  
	&.disabled .page-link {
	  color: #ced4da;
	  pointer-events: none;
	  cursor: auto;
	  background-color: $white;
	  border-color: #dee2e6;
	}
  }
  
  .pagination-lg {
	.page-link {
	  padding: 0.75rem 1.5rem;
	  font-size: 1.125rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	  }
	}
  }
  
  .pagination-sm {
	.page-link {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.875rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	  }
	}
  }
  
  /*-------Accordion-----------*/
  
  
  .panel-group .panel {
	border-radius: 4px;
	box-shadow: none;
	border:1px solid #e6ebf1;
  }
  
  .panel-default > .panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: #f0f0f2;
  }
  
  .panel-title {
	font-size: 14px;
	margin-bottom: 0;
  
	> a {
	  display: block;
	  padding: 15px;
	  text-decoration: none;
	}
  }
  
  .more-less {
	float: right;
	color: #212121;
  }
  
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border: 0px solid $border;
	line-height: 25px;
	font-size: 14px;
  }
  
  .panel > .list-group {
	margin-bottom: 0;
  
	.list-group-item:first-child {
	  border-top-right-radius: 0;
	  border-top-left-radius: 0;
	}
  }
  
  .panel-heading + .list-group .list-group-item:first-child {
	border-top-width: 0;
  }
  
  .panel-default .list-group-item.active {
	color: $black;
	background-color: #f0f0f2;
	border-color: #f0f0f2;
  }
  
  .panel-primary .list-group-item.active:hover {
	color: #FFF !important;
	background-color: #664dc9;
	border-color: #664dc9;
  }
  
  .panel-success .list-group-item.active {
	color: $white;
	background-color: #38cb89;
	border-color: #38cb89;
  
	&:hover {
	  color: $white !important;
	  background-color: #38cb89;
	  border-color: #38cb89;
	}
  }
  
  .panel-info .list-group-item.active {
	color: $white;
	background-color: #3e80eb;
	border-color: #3e80eb;
  
	&:hover {
	  color: $white !important;
	  background-color: #3e80eb;
	  border-color: #3e80eb;
	}
  }
  
  .panel-warning .list-group-item.active {
	color: $white;
	background-color: #ffab00;
	border-color: #ffab00;
  
	&:hover {
	  color: $white !important;
	  background-color: #ffab00;
	  border-color: #ffab00;
	}
  }
  
  .panel-danger .list-group-item.active {
	color: $white;
	background-color: #ef4b4b;
	border-color: #ef4b4b;
	&:hover {
	  color: $white !important;
	  background-color: #ef4b4b;
	  border-color: #ef4b4b;
	}
  }
  
  .panel-default .list-group-item.active {
	&:hover, &:focus {
	  color: $black;
	  background-color: #f0f0f2;
	  border-color: #f0f0f2;
	}
  }
  
  .wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
  }
  
  a {
	&:focus, &:hover, &:active {
	  outline: 0;
	  text-decoration: none;
	}
  }
  
  .panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  
  .panel-group1 {
	> .panel1:first-child .panel-heading1 {
	  border-radius: 4px 4px 0 0;
	}
  
	.panel1 {
	  border-radius: 0;
	}
  }
  
  .panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .panel-group1 .panel1 + .panel1 {
	margin-top: 0;
  }
  
  .panel-heading1 {
	color: $white;
	padding: 0;
  }
  
  .panel-group1 .panel-body {
	border: 1px solid $border;
  }
  
  .panel-title1 a {
	display: block;
	color: $white;
	padding: 12px 20px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
	border-radius:4px;
  }
  .panel-title1 [aria-expanded="true"] {
  	border-bottom-right-radius:0px;
  	border-bottom-left-radius:0px;
  	}
  
  #accordion1 .panel-title1 a:hover {
	color: $white !important;
  }
  
  .panel-body1 {
	background: $white;
	padding: 10px;
  }
  
  .panel1:last-child {
	.panel-body1 {
	  border-radius: 0 0 4px 4px;
	}
  
	.panel-heading1 {
	  border-radius: 0 0 4px 4px;
	  transition: border-radius 0.3s linear 0.2s;
  
	  &.active {
		border-radius: 0;
		transition: border-radius linear 0s;
	  }
	}
  }
  
  /* #bs-collapse icon scale option */
  
  .panel-title a.accordion-toggle {
	&:before {
	  content: "\f068";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  
	&.collapsed:before {
	  content: "\f067";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  }
  
  .panel-heading1 {
	a {
	  &.collapsed:before {
		content: "\e9af";
		position: absolute;
		font-family: 'feather' !important;
		right: 10px;
		top: 8px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
	  }
  
	  &:before {
		content: "\e994";
		position: absolute;
		font-family: 'feather' !important;
		right: 10px;
		top: 8px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
	  }
	}
  
	&.active a:before {
	  content: ' ';
	  transition: all 0.5s;
	  transform: scale(0);
	}
  }
  
  .demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
  }
  
  #bs-collapse .panel-heading1 {
	a:after {
	  content: "\f01a";
	  font-size: 24px;
	  position: absolute;
	  font-family: 'FontAwesome';
	  right: 5px;
	  top: 10px;
	  transform: scale(0);
	  transition: all 0.5s;
	}
  
	&.active a:after {
	  content: "\f01a";
	  transform: scale(1);
	  transition: all 0.5s;
	}
  }
  
  /* #accordion rotate icon option */
  
  #accordion .panel-heading1 {
	a:before {
	  content: "\f01a";
	  font-size: 24px;
	  position: absolute;
	  font-family: 'FontAwesome';
	  right: 5px;
	  top: 10px;
	  transform: rotate(180deg);
	  transition: all 0.5s;
	}
  
	&.active a:before {
	  transform: rotate(0deg);
	  transition: all 0.5s;
	}
  }
  
  .acc-header a {
	display: block;
	padding: 15px;
	background-color: #f0f0f2;
	border: 1px solid $border;
	border-radius:4px;
  }
  .acc-header [aria-expanded="true"] {
        border-bottom-right-radius:0px;
        border-bottom-left-radius:0px;
        }
  .acc-body {
	padding: 15px;
	border: 1px solid $border;
  }