/*-- rating--*/

.rating-stars {
	width: 100%;
	text-align: center;
  
	.rating-stars-container {
	  font-size: 0px;
  
	  .rating-star {
		display: inline-block;
		font-size: 30px;
		cursor: pointer;
		padding: 0px 5px;
		color: #f0f0f2;
  
		&.sm {
		  display: inline-block;
		  font-size: 14px;
		  color: 1px solid $border;
		  cursor: pointer;
		  padding: 5px;
		}
  
		&.is--active, &.is--hover {
		  color: #f1c40f;
		}
  
		&.is--no-hover {
		  color: #3e4b5b;
		}
  
		&.is--active .fa-heart, &.is--hover .fa-heart {
		  color: $danger;
		}
  
		.fa-heart .is--no-hover {
		  color: #3e4b5b;
		}
	  }
	}
  
	input {
	  display: block;
	  margin: 0 auto;
	  text-align: center;
	  padding: 0.375rem 0.75rem;
	  font-size: 0.9375rem;
	  line-height: 1.6;
	  color: #495057;
	  background-color: $white;
	  background-clip: padding-box;
	  border: 1px solid $border;
	  border-radius: 3px;
	  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
  }
  
  .br-theme-bars-horizontal .br-widget a, .br-theme-bars-pill .br-widget a {
	background-color: #f0f0f2;
  }
  
  .br-theme-bars-square .br-widget a {
	border: 2px solid #f0f0f2;
	background-color: $white;
	color: #f0f0f2;
  }
  
  .br-theme-bars-movie .br-widget a, .br-theme-bars-1to10 .br-widget a {
	background-color: #f0f0f2;
  }
  
  .br-theme-fontawesome-stars .br-widget a {
	&.br-active:after, &.br-selected:after {
	  color: $warning;
	}
  }
  
  .br-theme-fontawesome-stars-o .br-widget a {
	&.br-active:after, &.br-selected:after {
	  content: '\f005';
	  color: $warning;
	}
  
	&.br-fractional:after {
	  content: '\f123';
	  color: $warning;
	}
  }
  
  .br-theme-fontawesome-stars .br-widget a:after {
	content: '\f005';
	color: $warning;
  }
  
  .br-theme-fontawesome-stars-o .br-widget a:after {
	content: '\f006';
	color: $warning;
  }