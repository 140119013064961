/**
Dimmer
*/

.dimmer {
	position: relative;
  
	.loader {
	  display: none;
	  margin: 0 auto;
	  position: absolute;
	  top: 50%;
	  left: 0;
	  right: 0;
	  -webkit-transform: translateY(-50%);
	  transform: translateY(-50%);
	}
  
	&.active {
	  .loader {
		display: block;
	  }
  
	  .dimmer-content {
		opacity: .04;
		pointer-events: none;
	  }
	}
  }
  
  /*-------- Loaders ---------*/
  /*Spineer3*/
  
  .spinner3 {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;
	-webkit-animation: sk-rotate 2.0s infinite linear;
	animation: sk-rotate 2.0s infinite linear;
  }
  
  .dot1 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	border-radius: 100%;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	border-radius: 100%;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
	top: auto;
	bottom: 0;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-rotate {
	100% {
	  -webkit-transform: rotate(360deg);
	}
  }
  
  @keyframes sk-rotate {
	100% {
	  transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	}
  }
  
  /*End of Spineer3*/
  
  /*Spineer4*/
  
  .spinner4 {
	margin: 100px auto;
	width: 70px;
	text-align: center;
  
	> div {
	  width: 18px;
	  height: 18px;
	  border-radius: 100%;
	  display: inline-block;
	  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}
  
	.bounce1 {
	  -webkit-animation-delay: -0.32s;
	  animation-delay: -0.32s;
	}
  
	.bounce2 {
	  -webkit-animation-delay: -0.16s;
	  animation-delay: -0.16s;
	}
  }
  
  @-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	}
  
	40% {
	  -webkit-transform: scale(1);
	}
  }
  
  @keyframes sk-bouncedelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
  
	40% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}
  }
  
  /*End of Spineer4*/
  
  /*Sk circle*/
  
  .sk-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
  
	.sk-child {
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  left: 0;
	  top: 0;
  
	  &:before {
		content: '';
		display: block;
		margin: 0 auto;
		width: 15%;
		height: 15%;
		border-radius: 100%;
		-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
		animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	  }
	}
  
	.sk-circle2 {
	  -webkit-transform: rotate(30deg);
	  -ms-transform: rotate(30deg);
	  transform: rotate(30deg);
	}
  
	.sk-circle3 {
	  -webkit-transform: rotate(60deg);
	  -ms-transform: rotate(60deg);
	  transform: rotate(60deg);
	}
  
	.sk-circle4 {
	  -webkit-transform: rotate(90deg);
	  -ms-transform: rotate(90deg);
	  transform: rotate(90deg);
	}
  
	.sk-circle5 {
	  -webkit-transform: rotate(120deg);
	  -ms-transform: rotate(120deg);
	  transform: rotate(120deg);
	}
  
	.sk-circle6 {
	  -webkit-transform: rotate(150deg);
	  -ms-transform: rotate(150deg);
	  transform: rotate(150deg);
	}
  
	.sk-circle7 {
	  -webkit-transform: rotate(180deg);
	  -ms-transform: rotate(180deg);
	  transform: rotate(180deg);
	}
  
	.sk-circle8 {
	  -webkit-transform: rotate(210deg);
	  -ms-transform: rotate(210deg);
	  transform: rotate(210deg);
	}
  
	.sk-circle9 {
	  -webkit-transform: rotate(240deg);
	  -ms-transform: rotate(240deg);
	  transform: rotate(240deg);
	}
  
	.sk-circle10 {
	  -webkit-transform: rotate(270deg);
	  -ms-transform: rotate(270deg);
	  transform: rotate(270deg);
	}
  
	.sk-circle11 {
	  -webkit-transform: rotate(300deg);
	  -ms-transform: rotate(300deg);
	  transform: rotate(300deg);
	}
  
	.sk-circle12 {
	  -webkit-transform: rotate(330deg);
	  -ms-transform: rotate(330deg);
	  transform: rotate(330deg);
	}
  
	.sk-circle2:before {
	  -webkit-animation-delay: -1.1s;
	  animation-delay: -1.1s;
	}
  
	.sk-circle3:before {
	  -webkit-animation-delay: -1s;
	  animation-delay: -1s;
	}
  
	.sk-circle4:before {
	  -webkit-animation-delay: -0.9s;
	  animation-delay: -0.9s;
	}
  
	.sk-circle5:before {
	  -webkit-animation-delay: -0.8s;
	  animation-delay: -0.8s;
	}
  
	.sk-circle6:before {
	  -webkit-animation-delay: -0.7s;
	  animation-delay: -0.7s;
	}
  
	.sk-circle7:before {
	  -webkit-animation-delay: -0.6s;
	  animation-delay: -0.6s;
	}
  
	.sk-circle8:before {
	  -webkit-animation-delay: -0.5s;
	  animation-delay: -0.5s;
	}
  
	.sk-circle9:before {
	  -webkit-animation-delay: -0.4s;
	  animation-delay: -0.4s;
	}
  
	.sk-circle10:before {
	  -webkit-animation-delay: -0.3s;
	  animation-delay: -0.3s;
	}
  
	.sk-circle11:before {
	  -webkit-animation-delay: -0.2s;
	  animation-delay: -0.2s;
	}
  
	.sk-circle12:before {
	  -webkit-animation-delay: -0.1s;
	  animation-delay: -0.1s;
	}
  }
  
  @-webkit-keyframes sk-circleBounceDelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
  
	40% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}
  }
  
  @keyframes sk-circleBounceDelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
  
	40% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}
  }
  
  /*End of Sk circle*/
  
  /*Sk cube*/
  
  .sk-cube-grid {
	width: 40px;
	height: 40px;
	margin: 100px auto;
  
	.sk-cube {
	  width: 33%;
	  height: 33%;
	  float: left;
	  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	}
  
	.sk-cube1 {
	  -webkit-animation-delay: 0.2s;
	  animation-delay: 0.2s;
	}
  
	.sk-cube2 {
	  -webkit-animation-delay: 0.3s;
	  animation-delay: 0.3s;
	}
  
	.sk-cube3 {
	  -webkit-animation-delay: 0.4s;
	  animation-delay: 0.4s;
	}
  
	.sk-cube4 {
	  -webkit-animation-delay: 0.1s;
	  animation-delay: 0.1s;
	}
  
	.sk-cube5 {
	  -webkit-animation-delay: 0.2s;
	  animation-delay: 0.2s;
	}
  
	.sk-cube6 {
	  -webkit-animation-delay: 0.3s;
	  animation-delay: 0.3s;
	}
  
	.sk-cube7 {
	  -webkit-animation-delay: 0s;
	  animation-delay: 0s;
	}
  
	.sk-cube8 {
	  -webkit-animation-delay: 0.1s;
	  animation-delay: 0.1s;
	}
  
	.sk-cube9 {
	  -webkit-animation-delay: 0.2s;
	  animation-delay: 0.2s;
	}
  }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
	  -webkit-transform: scale3D(1, 1, 1);
	  transform: scale3D(1, 1, 1);
	}
  
	35% {
	  -webkit-transform: scale3D(0, 0, 1);
	  transform: scale3D(0, 0, 1);
	}
  }
  
  @keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
	  -webkit-transform: scale3D(1, 1, 1);
	  transform: scale3D(1, 1, 1);
	}
  
	35% {
	  -webkit-transform: scale3D(0, 0, 1);
	  transform: scale3D(0, 0, 1);
	}
  }
  
  /*End of Sk Cube*/
  
  /*sk-folding-cube*/
  
  .sk-folding-cube {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
  
	.sk-cube {
	  float: left;
	  width: 50%;
	  height: 50%;
	  position: relative;
	  -webkit-transform: scale(1.1);
	  -ms-transform: scale(1.1);
	  transform: scale(1.1);
  
	  &:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
		animation: sk-foldCubeAngle 2.4s infinite linear both;
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	  }
	}
  
	.sk-cube2 {
	  -webkit-transform: scale(1.1) rotateZ(90deg);
	  transform: scale(1.1) rotateZ(90deg);
	}
  
	.sk-cube3 {
	  -webkit-transform: scale(1.1) rotateZ(180deg);
	  transform: scale(1.1) rotateZ(180deg);
	}
  
	.sk-cube4 {
	  -webkit-transform: scale(1.1) rotateZ(270deg);
	  transform: scale(1.1) rotateZ(270deg);
	}
  
	.sk-cube2:before {
	  -webkit-animation-delay: 0.3s;
	  animation-delay: 0.3s;
	}
  
	.sk-cube3:before {
	  -webkit-animation-delay: 0.6s;
	  animation-delay: 0.6s;
	}
  
	.sk-cube4:before {
	  -webkit-animation-delay: 0.9s;
	  animation-delay: 0.9s;
	}
  }
  
  @-webkit-keyframes sk-foldCubeAngle {
	0%, 10% {
	  -webkit-transform: perspective(140px) rotateX(-180deg);
	  transform: perspective(140px) rotateX(-180deg);
	  opacity: 0;
	}
  
	25%, 75% {
	  -webkit-transform: perspective(140px) rotateX(0deg);
	  transform: perspective(140px) rotateX(0deg);
	  opacity: 1;
	}
  
	90%, 100% {
	  -webkit-transform: perspective(140px) rotateY(180deg);
	  transform: perspective(140px) rotateY(180deg);
	  opacity: 0;
	}
  }
  
  @keyframes sk-foldCubeAngle {
	0%, 10% {
	  -webkit-transform: perspective(140px) rotateX(-180deg);
	  transform: perspective(140px) rotateX(-180deg);
	  opacity: 0;
	}
  
	25%, 75% {
	  -webkit-transform: perspective(140px) rotateX(0deg);
	  transform: perspective(140px) rotateX(0deg);
	  opacity: 1;
	}
  
	90%, 100% {
	  -webkit-transform: perspective(140px) rotateY(180deg);
	  transform: perspective(140px) rotateY(180deg);
	  opacity: 0;
	}
  }
  
  /*End of sk-folding-cube*/
  
  /*Spiiner5*/
  
  .spinner5 {
	margin: 100px auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
  
	> div {
	  height: 100%;
	  width: 6px;
	  display: inline-block;
	  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	  animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}
  
	.rect2 {
	  -webkit-animation-delay: -1.1s;
	  animation-delay: -1.1s;
	}
  
	.rect3 {
	  -webkit-animation-delay: -1.0s;
	  animation-delay: -1.0s;
	}
  
	.rect4 {
	  -webkit-animation-delay: -0.9s;
	  animation-delay: -0.9s;
	}
  
	.rect5 {
	  -webkit-animation-delay: -0.8s;
	  animation-delay: -0.8s;
	}
  }
  
  @-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% {
	  -webkit-transform: scaleY(0.4);
	}
  
	20% {
	  -webkit-transform: scaleY(1);
	}
  }
  
  @keyframes sk-stretchdelay {
	0%, 40%, 100% {
	  transform: scaleY(0.4);
	  -webkit-transform: scaleY(0.4);
	}
  
	20% {
	  transform: scaleY(1);
	  -webkit-transform: scaleY(1);
	}
  }
  
  /*End of Spiiner5*/
  
  .spinner {
	width: 40px;
	height: 40px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  .spinner-lg {
	width: 100px;
	height: 100px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotateplane {
	0% {
	  -webkit-transform: perspective(120px);
	}
  
	50% {
	  -webkit-transform: perspective(120px) rotateY(180deg);
	}
  
	100% {
	  -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
  }
  
  @keyframes sk-rotateplane {
	0% {
	  transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	  -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
  
	50% {
	  transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	  -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
  
	100% {
	  transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	  -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
  }
  
  .spinner1 {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
  }
  
  .spinner1-lg {
	width: 100px;
	height: 100px;
	position: relative;
	margin: 100px auto;
  }
  
  .double-bounce1 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
	0%, 100% {
	  -webkit-transform: scale(0);
	}
  
	50% {
	  -webkit-transform: scale(1);
	}
  }
  
  @keyframes sk-bounce {
	0%, 100% {
	  transform: scale(0);
	  -webkit-transform: scale(0);
	}
  
	50% {
	  transform: scale(1);
	  -webkit-transform: scale(1);
	}
  }
  
  .spinner2 {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
  }
  
  .cube1 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
  }
  
  .cube2 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-cubemove {
	25% {
	  -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
  
	50% {
	  -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
  
	75% {
	  -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
  
	100% {
	  -webkit-transform: rotate(-360deg);
	}
  }
  
  @keyframes sk-cubemove {
	25% {
	  transform: translateX(42px) rotate(-90deg) scale(0.5);
	  -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
  
	50% {
	  transform: translateX(42px) translateY(42px) rotate(-179deg);
	  -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
  
	50.1% {
	  transform: translateX(42px) translateY(42px) rotate(-180deg);
	  -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
  
	75% {
	  transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	  -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
  
	100% {
	  transform: rotate(-360deg);
	  -webkit-transform: rotate(-360deg);
	}
  }
  
  .lds-heart {
	position: relative;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;
	margin: 100px auto;
  
	div {
	  top: 23px;
	  left: 19px;
	  position: absolute;
	  width: 26px;
	  height: 26px;
	  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  
	  &:after {
		content: " ";
		position: absolute;
		display: block;
		width: 26px;
		height: 26px;
	  }
  
	  &:before {
		content: " ";
		position: absolute;
		display: block;
		width: 26px;
		height: 26px;
		left: -17px;
		border-radius: 50% 0 0 50%;
	  }
  
	  &:after {
		top: -17px;
		border-radius: 50% 50% 0 0;
	  }
	}
  }
  
  @keyframes lds-heart {
	0% {
	  transform: scale(0.95);
	}
  
	5% {
	  transform: scale(1.1);
	}
  
	39% {
	  transform: scale(0.85);
	}
  
	45% {
	  transform: scale(1);
	}
  
	60% {
	  transform: scale(0.95);
	}
  
	100% {
	  transform: scale(0.9);
	}
  }
  
  .lds-ring {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 100px auto;
  
	div {
	  box-sizing: border-box;
	  display: block;
	  position: absolute;
	  width: 51px;
	  height: 51px;
	  margin: 6px;
	  border-radius: 50%;
	  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  
	  &:nth-child(1) {
		animation-delay: -0.45s;
	  }
  
	  &:nth-child(2) {
		animation-delay: -0.3s;
	  }
  
	  &:nth-child(3) {
		animation-delay: -0.15s;
	  }
	}
  }
  
  @keyframes lds-ring {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }
  
  .lds-hourglass {
	position: relative;
	width: 40px;
	height: 40px;
	margin: 100px auto;
  
	&:after {
	  content: " ";
	  display: block;
	  border-radius: 50%;
	  width: 0;
	  height: 0;
	  margin: 6px;
	  box-sizing: border-box;
	  animation: lds-hourglass 1.2s infinite;
	}
  }
  
  @keyframes lds-hourglass {
	0% {
	  transform: rotate(0);
	  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
  
	50% {
	  transform: rotate(900deg);
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
  
	100% {
	  transform: rotate(1800deg);
	}
  }

  .loader7{
	position:relative;
	margin: 100px auto;
	width: 100px;
	height: 40px;
  }
  .circles{
	display: inline-block;
	width: 15px;
	height: 15px;
	background-color: $primary;
	border-radius: 50%;
	animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
	transform-origin: bottom center;
	position: relative;
  }
  @keyframes loading{
	0%{
	  transform: translateY(0px);
	  background-color: #664dc9;
	}
	50%{
	  transform: translateY(50px);
	  background-color: #4d30c5;
	}
	100%{
	  transform: translateY(0px);
	  background-color: #3917c2;
	}
  }
  .circle-1{
	animation-delay: 0.1s;
  }
  .circle-2{
	animation-delay: 0.2s;
  }
  .circle-3{
	animation-delay: 0.3s;
  }
  .circle-4{
	animation-delay: 0.4s;
  }
  .circle-5{
	animation-delay: 0.5s;
  }

  $color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe; 

  .loader8 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	overflow: hidden;
	margin: 100px auto;
  }
  
  $circle-size: 15px;
  $circle-margin: 7px; 
  
  .circle1 {
	width: $circle-size;
	height: $circle-size;
	border-radius: 50%;
	margin: $circle-margin;
	display: flex;
	justify-content: center;
	align-items: center;
  
	&:before {
	  content: "";
	  width: $circle-size;
	  height: $circle-size;
	  border-radius: 50%;
	  opacity: 0.7;
	  animation: scale 2s infinite 
		cubic-bezier(0, 0, 0.49, 1.02);
	}
  }
  
  @for $i from 1 through 5 {
	.circle-#{$i} {
	  background-color: nth($color, $i);
	  &:before {
		background-color: nth($color, $i);
		animation-delay: 200ms * $i;
	  }
	}
  }
  
  @keyframes scale {
	0% {
	  transform: scale(1);
	}
	50%, 75% {
	  transform: scale(2.5);
	}
	78%, 100% {
	  opacity: 0;
	}
  }
  